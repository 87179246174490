/**
 * Functions that add data to local-storage.
 *
 * Exports
 *     addContribData
 *     addInteractionRole
 *     addInteractionToEntity
 *     addToParentRcrd
 *     addToRcrdAryProp
 *     addToRcrdProp
 *     addToTaxonNames
 *     updateDataProps
 */
import * as util from '@localdata/util';
import { cloneObj } from '@util';
import * as _t from '@types';
/** Add the new record to the prop's stored records object.  */
export function addToRcrdProp (
    prop: string,
    rcrd: _t.EntityRecord,
    entity: string
): void {
    const rcrds = util.getValue( prop ) as _t.EntityRecords;           /*dbug-log*///console.log("               --addToRcrdProp. [%s][%O]. rcrd[%O]", prop, cloneObj(rcrds), cloneObj(rcrd));
    if ( rcrds ) {
        rcrds[ rcrd.id ] = rcrd;
        util.storeData( prop, rcrds );
    } else {
        console.error( `${ entity } records not found` );
    }
}
/** Add the new record to the prop's stored records object.  */
export function addToRcrdAryProp (
    prop: 'authSrcs' | 'pubSrcs' | 'publSrcs',
    rcrd: _t.EntityRecord,
    _1: 'author' | 'publication' | 'publisher'
): void {
    const rcrds = util.getValue( prop ) as number[];                /*dbug-log*///console.log( "               --addToRcrdAryProp. [%s] = %O. rcrd = %O", prop, rcrds, rcrd );
    if ( !ifNewRcrd( rcrds, rcrd.id ) ) return;
    rcrds.push( rcrd.id );
    util.storeData( prop, rcrds );
}
function ifNewRcrd ( ary: number[], id: number ): boolean {
    return ary.indexOf( id ) === -1;
}
/** Adds a new child record's id to it's parent's 'children' array. */
export function addToParentRcrd (
    prop: 'location' | 'source' | 'taxon',
    rcrd: _t.EntityRecord,
    _1: 'location' | 'source' | 'taxon'
): void {
    if ( !rcrd.parent ) return;
    const [ rcrds, parent ] = util.getEntityData( prop, rcrd.parent );     /*dbug-log*///console.log( "               --addToParentRcrd. [%s] = %O. rcrd = %O", prop, rcrds, rcrd );
    if ( !ifNewRcrd( parent.children, rcrd.id ) ) return;
    parent.children.push( rcrd.id );
    util.storeData( prop, rcrds );
}
/**
 * Adds the Taxon's name to the stored names for it's group and rank.
 * Note: 'group' is added above, so the taxon from storage is used rather than the rcrd.
 */
export function addToTaxonNames (
    _1: 'taxonNames',
    rcrd: _t.EntityRecord,
    _2: 'taxon`'
): void {                                                           /*dbug-log*///console.log('addToTaxonNames. prop[%s] rcrd[%O]', prop, rcrd);
    const [ _, taxon ] = util.getEntityData( 'taxon', rcrd.id );           /*dbug-log*///console.log('   taxon[%O]', taxon);
    const group = taxon.group.displayName;
    const root = taxon.group.root.name;
    const rank = taxon.rank.displayName;
    const nameProp = group + root + rank + "Names";
    let data = ( util.getValue( nameProp ) || {} ) as _t.IdsByName;
    data[ taxon.name ] = taxon.id; //done here because taxa use a base 'name' property, as they display typically with the rank prepended
    util.storeData( nameProp, data );
}
/** Adds the Interaction to the stored entity's collection.  */
export function addInteractionToEntity (
    prop: 'location' | 'source',
    rcrd: _t.EntityRecord,
    _1: 'interaction'
): void {                                                           /*dbug-log*///console.log( 'addInteractionToEntity. prop = [%s] rcrd = %O', prop, rcrd );
    if ( !rcrd[ prop ] ) return;
    const [ rcrds, stored ] = util.getEntityData( prop, rcrd[ prop ] );
    if ( !ifNewRcrd( stored.interactions, rcrd.id ) ) return;       /*dbug-log*///console.log('--adding interaction.')
    stored.interactions.push( rcrd.id );
    if ( prop === 'source' ) stored.isDirect = true;
    util.storeData( prop, rcrds );
}
/** Adds the Interaction to the taxon's subject/objectRole collection.  */
export function addInteractionRole (
    prop: 'subject' | 'object',
    rcrd: _t.EntityRecord,
    _1: 'interaction'
): void {
    const [ taxa, taxon ] = util.getEntityData( 'taxon', rcrd[ prop ] );
    if ( !ifNewRcrd( taxon[ prop + "Roles" ], rcrd.id ) ) return;
    taxon[ prop + "Roles" ].push( rcrd.id );
    util.storeData( "taxon", taxa );
}
/** When a Publication/Citation has been updated, add new author contributions. */
export function addContribData (
    prop: 'authors' | 'editors',
    rcrd: _t.EntityRecord,
    _1: 'publication' | 'citation'
): void {                                                           /*dbug-log*///console.log( "               --addContribData. aType[%s]rcrd[%O]", prop, rcrd );
    if ( !rcrd[ prop ] ) return;
    const rcrds = util.getEntities( 'source' );
    _t.objectValues( rcrd[ prop ] ).forEach( id => addNewContribData( id, rcrds, rcrd ) );
    util.storeData( 'source', rcrds );
}
function addNewContribData ( id: number, rcrds: _t.EntityRecords, rcrd: _t.EntityRecord ): void {
    const author = util.getEntity( rcrds, id, 'source' );            /*dbug-log*///console.log( "                   --addNewContribData author[%O] source[%O] rcrds[%O]", author, rcrd, rcrds );
    if ( !ifNewRcrd( author.contributions, rcrd.id ) ) return;
    author.contributions.push( rcrd.id );
}

/**
 * Alerts the user of an issue and handles submitting new events to the issue-tracker, Sentry.
 *
 * Export
 *     alertIssue
 *     reportErr
 *
 * TOC
 *     ALERT DATA-TYPES
 *     CREATE SENTRY EVENT
 *         EDITOR ISSUE REPORT
 *         SENTRY ERROR OBJECT
 *     ALERT USER
 */
import * as Sentry from "@sentry/browser";
import { logInDevEnv } from './misc-util';
import { objectKeys } from "@types";
/* ==================== ALERT DATA-TYPES ==================================== */
/** Tag: dataSyncFailure, dataPrepFail */
type FailedAlertData = {
    fails: string | string[];
};
/** Tag: feedback */
type FeedbackAlertData = {
    route: string;
    topic: string;
    feedback: string;
};
/** Tag: fetchIssue */
type FetchAlertData = {
    url: string;
    responseText: string;
};
/** Tag: expectedDataNotFound, miscAlert, undefinedDataKey */
type GenericAlertData = {
    [key: string]: string | null | undefined;
};
/** Tag: invalidDataKeyType */
type InvalidAlertData = {
    key: string;
    type: string;
};
/** Tag: facadeErr */
type ModuleAlertData = {
    module: string;
    caller: string;
    called: string;
    error: string; //Error.toString
    errMsg: string; //Error.message
};
/** Tag: noRcrdFound */
type NoRecordAlertData = {
    id: string | number;
    entity: string;
};
type AlertData = (
    FailedAlertData |
    FeedbackAlertData |
    FetchAlertData |
    GenericAlertData |
    InvalidAlertData |
    ModuleAlertData |
    NoRecordAlertData
);
type AlertAttrs = {
    [key: string]: { [key:string]: any } | null;
};
/* =================== CREATE SENTRY EVENT ================================== */
/** Sends Error object to Sentry, issue tracker. */
export function reportErr ( e: Error ): void {
    Sentry.captureException( e );
}
export function alertIssue (
    tag: string,
    errData: AlertData,
    context?: AlertAttrs
): void {
    if ( ifDevEnvThenLogOnly( tag, errData ) ) return;
    /*perm-log*/console.log( "       !!!alertIssue [%s] = %O", tag, errData );
    const err = new SentryError( tag, errData );
    const tags = getAlertTags( errData, context );
    Sentry.captureException( err, tags );
}
/* ------------------------- LOG -------------------------------------------- */
function ifDevEnvThenLogOnly ( tag: string, errData: AlertData | null ): boolean {
    logAlertInDev( tag, errData );
    return $( 'body' ).data( 'env' ) !== 'prod';
}
function logAlertInDev ( tag: string, errData: AlertData | null ): void {
    if ( tag === 'feedback' ) return; //This sends a report in prod, but is not an actual issue.
    try {
        logInDevEnv( '!!ALERT ISSUE [%s] = %O', tag, errData );
    } catch ( e ) { /* When error occurs before module is fully loaded. */ }
}
/* --------------------- HANDLE EVENT --------------------------------------- */
function getAlertTags(
    errData: AlertData,
    context?: AlertAttrs
): Record<string, any> {
    let tags: Record<string, any> = {};
    if ( context ) tags = setBasicStateContext( context, tags );
    return { ...tags, 'error_data': errData };
}
function setBasicStateContext ( context: AlertAttrs, tags: Record<string, any> ): Record<string, any> {
    const key = objectKeys( context )[ 0 ]!;
    const data = context[ key as keyof AlertAttrs ]!;
    return { ...tags, [ key ]: data }
}
/* ------------------------ Sentry Error Object ----------------------------- */
/** Extends the Error object to add debug data for the error.  */
class SentryError extends Error {
    constructor ( tag: string, debugData: AlertData | string, ...params: any[] ) {
        // Pass remaining arguments (including vendor specific ones) to parent constructor
        super( ...params );
        // Maintains proper stack trace for where our error was thrown (only available on V8)
        if ( Error.captureStackTrace ) Error.captureStackTrace( this, SentryError );
        // Custom debugging information
        this.name = tag;
        this.message = JSON.stringify( debugData );
    }
}

/**
 * Main access point for combobox utilities.
 *
 * TOC
 *     INIT
 *         CONFIGS
 *     GET
 *         OPTIONS
 *         SELECTED
 *     SET
 *         CHANGE EVENT
 *         OPTIONS
 *         PLACEHOLDER
 *         RESET
 *         SELECTED
 *     HELPERS
 *         DESTROY
 *         (EN/DIS)ABLE COMBOBOXES
 *         FOCUS COMBOBOX
 */
import { OptionObject } from '@types';
import { alertIssue, reportErr } from '@util';
import { Combobox, ComboboxConfig } from './Combobox';

/** Active Selectize configuration objects. Field name (k): confg (v)  */
const combos: { [key: string]: Combobox; } = {};
/* =========================== INIT ========================================= */
export function initCombobox ( config: ComboboxConfig ) {           /*dbug-log*///console.log( '    -- initCombobox config[%O]', config );
    const combo = new Combobox( config );
    combos[ combo.configName ] = combo;
}
/* -------------------------- CONFIGS --------------------------------------- */
function getCombo ( field: string ): Combobox {
    const combobox = getConfg( field );                             /*dbug-log*///console.log( 'getCombo field[%s] configs[%O] combobox[%O]', field, combos, combobox );
    if ( !combobox ) throw Error( `Combobox [${ field }] not found` );
    return combobox;
}
function getConfg ( field: string, undefinedOk = false ): Combobox | void {
    const combo = combos[ getFieldConfgKey( field ) ];
    if ( combo ) return combo;
    if ( !undefinedOk ) alertIssue( 'comboboxNotFound', { field: field } );
}
function getFieldConfgKey ( field: string ): keyof typeof combos & string {
    return field.split( ' ' ).join( '' );
}
function getConfigKeyFromId ( id: string ): string {
    return id.split( 'sel-' )[ 1 ]!;
}
export function isComboActive ( field: string, undefinedOk = false ) {
    return !!getConfg( field, undefinedOk );
}
/* =========================== GET ========================================== */
/* -------------------------- OPTIONS --------------------------------------- */
export function getOptionTotal ( field: string ): number {
    return getCombo( field ).getOptionCount();
}
export function getOptionValueForText ( field: string, text: string ): string | false {
    return getCombo( field ).getOptionValueForText( text );
}
export function getOptionTextForValue ( field: string, val: string ): string | false {
    return getCombo( field ).getOptionTextForValue( val );
}
/* ------------------------- SELECTED --------------------------------------- */
export function getSelVal ( field: string ): string | null {
    return getCombo( field ).value;
}
export function getSelTxt ( field: string ): string | null {
    return getCombo( field ).text;
}
/* =========================== SET ========================================== */
/* -------------------- CHANGE EVENT ---------------------------------------- */
export function updateComboChangeEvent ( field: string, onChange: ComboboxConfig['onChange'] ): void {
    getCombo( field ).updateChangeListener( onChange );
}
export function triggerComboChangeReturnPromise ( field: string, val: string ): Promise<void> {
    return getCombo( field ).triggerChangeAndReturnPromise( val );
}
/* ------------------------ OPTIONS ----------------------------------------- */
export function replaceSelOpts ( field: string, opts: OptionObject[] ): void {
    getCombo( field ).replaceOptions( opts );
}
export function addOpt ( field: string, opt: OptionObject ): void {
    getCombo( field ).addOption( opt );
}
export function removeOptions ( field: string, vals: string[] ): void {
    getCombo( field ).removeOptions( vals );
}
/* ------------------------- PLACEHOLDER ------------------------------------ */
export function updatePlaceholderText ( field: string, optCnt?: number ): void {
    getCombo( field ).updatePlaceholder( optCnt );
}
/* ------------------------ RESET ------------------------------------------- */
export function resetCombobox ( field: string, clearOptions = false ): void {
    getCombo( field ).reset( clearOptions );
}
/* ------------------------- SELECTED --------------------------------------- */
export function setSelVal ( field: string, val: string, silent?: 'silent' ): void {
    getCombo( field ).setValue( val, !!silent );
}
/* ======================= HELPERS ========================================== */
/* ----------------------- DESTROY ------------------------------------------ */
export function destroySelectizeInstance ( field: string ): void {
    const combobox = getConfg( field, true );
    if ( !combobox ) return;
    combobox.destroy();
    delete combos[ combobox.configName ];
}
/* ----------------- (EN/DIS)ABLE COMBOBOXES -------------------------------- */
export function enableCombobox ( field: string, enable = true ): void {
    getCombo( field ).enable( enable );
}
export function enableComboboxes (
    $pElems: JQuery<HTMLSelectElement>,
    enable = true
): void {
    $pElems.each( ( i, elem ) => enableChildCombo( elem, enable ) );
}
function enableChildCombo( elem:HTMLSelectElement, enable = true ): void {
    const key = getConfigKeyFromId( elem.id );
    enableCombobox( key, enable );
}
export function enableFirstCombobox ( field: string, enable = true ): void {
    const combo = getFirstComboboxInContainer( `#${ field }_f-cntnr` );
    if ( !combo ) return;
    combo.enable( enable );
}
function getFirstComboboxInContainer ( container: string ): Combobox | false {
    const firstId = getFirstComboIdInContainer( container );
    if ( !firstId ) return false;
    const key = getConfigKeyFromId( firstId );
    return getCombo( key );
}
function getFirstComboIdInContainer( container: string ): string | false {
    const combos = $( `${ container } .selectized` );
    const firstCombo = combos[ 0 ];
    if ( !firstCombo ) { alertNoComboInContainer( container ); return false; }
    return firstCombo.id;
}
function alertNoComboInContainer( container: string ): void {
    reportErr( new Error( `No comboboxes found in container [${ container }]` ) );
}
/* ------------------------- FOCUS COMBOBOX --------------------------------- */
export function focusCombobox ( field: string, focus = true ): void {
    getCombo( field ).focus( focus );
}

/**
 * Returns the field's value to use during server handling. Replaces quarantined
 * data with temporary data as needed.
 *
 * EXPORT
 *     getFieldValue
 *
 * TOC
 *     HANDLE RELATIONAL DATA
 *         GET ENTITY ID
 *         REPLACE QUARANTINED
 */
import { model, state } from '@dataentry';
import { isMultiField } from '@elems';
import { isNotNumber, isObj } from '@util';

export function getFieldValue( fConfig ) {                           /*dbug-log*///console.log('   +-- getFieldValue fConfig[%s][%O]', fConfig.name, fConfig);
    if ( state.isPossiblyQuarantined( fConfig ) ) return getFieldIdValue( fConfig );
    if ( isObj( fConfig.value ) ) return fConfig.value.value; //combos and multiFields
    return fConfig.value;
}
/** =================== HANDLE RELATIONAL DATA ============================== */
function getFieldIdValue( fConfig ) {
    if ( isMultiField( fConfig ) ) { return getMultiSelectVal( fConfig ); }
    const val = isObj( fConfig.value ) ? fConfig.value.value : fConfig.value;
    return getEntityId( fConfig, val );
}
/**
 * Returns false if empty to trigger validation, otherwise returns value object.
 * Quarantined ids are replaced with temporary ids for contributors.
 */
function getMultiSelectVal( fConfig ) {                              /*dbug-log*///console.log('           -- getMultiSelectVal fConfig[%s][%O]', fConfig.name, fConfig);
    const sanitized = {};
    const ids = Object.values( fConfig.value );
    $( ids ).each( ( i, v ) => {
        const pushId = getEntityId( fConfig, v, ids ).toString();    /*dbug-log*///console.log('               -- ord[%s] v[%s] pushId[%s]', i, v, pushId);
        sanitized[ i+1 ] = pushId;
        ids.push( pushId );
    } );
    return !ids.length ? false : sanitized;
}
/* ------------------------- GET ENTITY ID ---------------------------------- */
/**
 * Ensures the field-data is valid for the submit process. When field-data is
 * quarantined, temporary references to existing data are necessary.
 * @param  {obj}  fConfig    Field configuration
 * @param  {int}  id        ID of referenced entity
 * @param  {ary}  blacklist Entities referenced in record already.
 * @return {int}            Validated field-value
 */
function getEntityId( fConfig, id, blacklist ) {                     /*dbug-log*///console.log('       -- getEntityId id[%s] fConfig[%O] blacklist?[%O]', id, fConfig, blacklist);
    if ( isNotNumber( id ) ) return id;
    if ( ifEntityIdSet( fConfig ) ) return fConfig.review.entityId;
    const [ core, detail ] = model.getFieldClassData( fConfig );     /*dbug-log*///console.log('         -- classData core[%s] detail?[%s]', core, detail);
    const rcrdId = id ? getValidIdFromRecord( core, id ) : null;
    return rcrdId || getTempId( core, detail, blacklist );
}
/** If ReviewEntry is connected to an existing entity. */
function ifEntityIdSet( fConfig ) {
    return fConfig.review?.entityId;
}
function getValidIdFromRecord( core, id ) {
    const rcrd = state.getRecords( core, id );                       /*dbug-log*///console.log( '             -- getValidIdFromRecord rcrd[%O]', rcrd );
    return !rcrd?.review ? id : ( core === 'taxon' ? getRootTaxon( rcrd ) : false );
}
function getRootTaxon( taxon ) {
    return taxon.group.root.taxon;
}
/** --------------------- REPLACE QUARANTINED ------------------------------- */
/**
 * Temporary references to existing data are necessary during the submit process.
 * Quarantined data will be readded before it is stored in local storage.
 * @param  {string}  cEntity   Core Entity
 * @param  {string}  dEntity   Detail Entity
 * @param  {array}   blacklist Entities referenced in record already.
 * @return {integer}           Entity ID to push to server
 */
function getTempId( cEntity, dEntity, blacklist = [] ) {            /*dbug-log*///console.log( '-- getTempId cEntity[%s] dEntity?[%s] blacklist?[%O]', cEntity, dEntity, blacklist );
    const entity = dEntity || cEntity;
    const rcrds = state.getRecords( entity );
    const tEnt = Object.values( rcrds ).find( ifValidTempEntity )
    return !dEntity ? tEnt.id : tEnt[ cEntity ];

    function ifValidTempEntity( rcrd ) {                            /*dbug-log*///console.log( '   -- ifValidTempEntity rcrd[%O]', rcrd );
        const id = !dEntity ? rcrd.id : rcrd[ cEntity ];
        return !rcrd.review && blacklist.indexOf( id.toString() ) === -1;
    }
}
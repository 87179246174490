/**
 * Handles displaying the 'help' modal for editors and above with options to either
 * reset the local database or submit a bug report.
 *
 * TOC
 *    HELP MODAL
 *    BUG REPORT POPUP
 *        REPORT HTML
 *            PROMPTS
 *            BUTTONS
 *        SUBMIT REPORT
 *            SERVER
 *            SENTRY
 *        CLOSE REPORT POPUP
 */
import * as el from '@elems';
import { resetStoredData } from '@localdata';
import { alertIssue } from '@util';
import { initExploreStateAndTable, showIntroAndLoadingMsg } from '@explore/ui';
import * as Sentry from "@sentry/browser";
/* ===================== HELP MODAL ========================================= */
export default function showEditorHelpModal (): void {
    const confg = {
        html: getHelpHtml(),
        position: 'left',
        selector: '#data-help',
        title: 'Experiencing issues?'
    } as const;
    const events = { onafterchange: setBttnEvents };
    el.showSaveModal( confg, null, events );
}
function getHelpHtml (): string {
    return `<div style="text-align:center;">${ getModalBttn( 'Reset Local Data' ) }
        ${ getModalBttn( 'Report A Bug' ) }</div>`;
}
function getModalBttn ( text: string ): string {
    return `<button class="intro-bttn">${ text }</button>`;
}
function setBttnEvents (): void {
    const map = {
        'Reset Local Data': handleDataReset,
        'Report A Bug': showBugReportPopup
    } as const;
    $( '.intro-bttn' ).each( ( i, elem ) => {
        $( elem ).on( 'click', () => { el.exitModal( map[ elem.innerText as keyof typeof map ] ); } );
    } );
}
function handleDataReset (): void {
    showIntroAndLoadingMsg( 'taxa', true );
    resetStoredData()
    .then( () => initExploreStateAndTable() );
}
/* ===================== BUG REPORT POPUP =================================== */
async function showBugReportPopup (): Promise<void> {
    const feedback = Sentry.getFeedback();
    if ( !feedback ) {
        alertIssue( 'miscAlert', { message: 'Sentry.getFeedback failed' } );
        return;
    }
    const form = await feedback.createForm( {
        tags: {
            type: 'editorIssueReport'
        }
    } );
    form.appendToDom();
    form.open();
}
